import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import AppList from '../../../components/AppList';
import AppSwitch from '../../../components/AppSwitch';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectCategories } from '../../../redux/selectors/categoriesSelectors';
import { PAGE_CREATION_CATEGORIES_LIST_COLUMNS } from '../constants';
import { fetchCategoriesData } from '../redux/categoriesSlice';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { getFormattedPageCreationCategories } from '../utils/formatting.utils';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
  values?: string[];
};

const PageCategoriesList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { categoriesData, refreshing } = useAppSelector(selectCategories);

  const fetchCategoryDataCallback = useCallback(async () => {
    dispatch(setMenuPaginationSkip(undefined));
    dispatch(setMenuPaginationLimit(undefined));
    dispatch(setMenuPaginationSearchKey(''));
    await dispatch(fetchCategoriesData());
  }, [dispatch]);

  useEffect(() => {
    fetchCategoryDataCallback();
  }, [fetchCategoryDataCallback]);

  const formattedDishCategoriesData = useMemo(() => {
    return categoriesData.map(getFormattedPageCreationCategories);
  }, [categoriesData]);

  return (
    <AppList
      columns={PAGE_CREATION_CATEGORIES_LIST_COLUMNS}
      rows={formattedDishCategoriesData}
      hideHeader
      refreshing={refreshing}
      enableRowActions
      renderRowActions={(value) => {
        return (
          <AppSwitch
            id={value.key}
            name={'categories'}
            value={value.key}
            checked={
              Array.isArray(props.values)
                ? props.values.includes(value.key)
                : false
            }
            onChange={(e) => props.onChange(e, value.key)}
          />
        );
      }}
    />
  );
};

export default PageCategoriesList;
