import Tabs from '@mui/material/Tabs';
import { SxProps, Theme } from '@mui/material/styles';
import { ElementType, memo } from 'react';

import { Colors } from '../../theme/colors';
import TabItem from './TabItem';

export interface IVerticalTabItem {
  label: string;
  id: string;
  Icon?: ElementType;
  nestedTabs?: Omit<IVerticalTabItem, 'tags' | 'icon'>[];
  path: string;
}

export interface VerticalTabsProps {
  currentTab: string;
  currentNestedTab?: string;
  tabs: IVerticalTabItem[];
  handleTabChange: (tab: Omit<IVerticalTabItem, 'tags' | 'icon'>) => void;
  sx?: SxProps<Theme>;
  horizontal?: boolean;
}

export const AppVerticalTabs = memo(
  ({
    tabs,
    currentTab,
    sx = {},
    handleTabChange,
    currentNestedTab,
    horizontal,
  }: VerticalTabsProps) => {
    const value = tabs.findIndex((tab) => tab.id === currentTab);

    return (
      <Tabs
        orientation={horizontal ? 'horizontal' : 'vertical'}
        value={value}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          position: 'sticky',
          zIndex: 998,
          overflowY: 'auto',
          '& .MuiTabs-indicator': {
            left: 0,
          },
          '& .MuiTab-root': {
            marginBottom: 0,
            alignItems: 'baseline',
          },
          backgroundColor: Colors.menuTabsBackground,
          borderRadius: '8px',
          ...sx,
        }}>
        {tabs.map((tab: IVerticalTabItem, index) => (
          <TabItem
            key={tab.id}
            value={index}
            {...tab}
            handleTabChange={handleTabChange}
            currentTab={currentTab}
            currentNestedTab={currentNestedTab}
            horizontal={horizontal}
          />
        ))}
      </Tabs>
    );
  },
);

export default AppVerticalTabs;
