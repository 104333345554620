import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router-dom';

import { Colors } from '../theme/colors';

export interface IBreadCrumb {
  title: string;
  link: string;
}

type TabPageLayoutProps = {
  title?: string | React.ReactNode;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  breadCrumbs?: IBreadCrumb[];
  HeaderComponent?: () => JSX.Element;
  TabComponent?: () => JSX.Element;
  TabFooterComponent?: () => JSX.Element;
  ContentComponent?: () => JSX.Element | null | undefined;
  SideComponent?: () => JSX.Element;
  divider?: boolean;
  titleSx?: React.CSSProperties;
};

const AppPageLayout = ({
  title,
  titleVariant = 'h4',
  HeaderComponent,
  TabComponent,
  TabFooterComponent,
  ContentComponent,
  SideComponent,
  breadCrumbs,
  divider,
  titleSx,
}: TabPageLayoutProps) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        pt: 2,
        width: '100%',
      }}>
      {breadCrumbs ? (
        <Breadcrumbs
          sx={{
            mb: 2,
            ml: 1,
            mt: 2,
          }}>
          {breadCrumbs.map((breadCrumb, index) => (
            <Link
              component="button"
              onClick={() => {
                if (index === breadCrumbs.length - 1) {
                  return navigate('');
                }

                navigate(breadCrumb.link);
              }}
              underline="hover"
              key={breadCrumb.title}
              style={{
                color: Colors.fontColorSecondary,
                cursor: 'pointer',
              }}>
              {breadCrumb.title}
            </Link>
          ))}
        </Breadcrumbs>
      ) : null}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
          alignItems: 'center',
        }}>
        {typeof title === 'string' ? (
          <Typography variant={titleVariant} sx={{ my: 2, ...titleSx }}>
            {title}
          </Typography>
        ) : (
          title
        )}
        {SideComponent ? <SideComponent /> : null}
      </Box>

      {HeaderComponent ? <HeaderComponent /> : null}

      {divider ? <Divider sx={{ mb: 3 }} /> : null}

      <Grid
        container
        spacing={4}
        sx={{ height: TabComponent ? '100vh' : null }}>
        <Grid item xs={3}>
          <Box
            sx={{
              position: 'sticky',
              top: 120,
              zIndex: 1,
              backgroundColor: Colors.backgroundPrimary,
              width: '87%',
            }}>
            {TabComponent ? <TabComponent /> : null}
          </Box>
          <Box>{TabFooterComponent ? <TabFooterComponent /> : null}</Box>
        </Grid>
        <Grid item xs={9}>
          {ContentComponent ? <ContentComponent /> : <Outlet />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppPageLayout;
