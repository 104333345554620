import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { CREATE_PAGES_NAVIGATION_TABS } from '../constants';

const CreatePagesTabs = () => {
  const location = useLocation();

  const CREATE_PAGE_TABS: IVerticalTabItem[] = useMemo(
    () => [
      {
        id: CREATE_PAGES_NAVIGATION_TABS.BASIC_DETAILS,
        label: 'Basic Details',
        Icon: RestaurantIcon,
        path: `${location.pathname}#basic-details`,
      },
      {
        id: CREATE_PAGES_NAVIGATION_TABS.CATEGORIES,
        label: 'Categories',
        Icon: RestaurantIcon,
        path: `${location.pathname}#categories`,
      },
      {
        id: CREATE_PAGES_NAVIGATION_TABS.DISHES,
        label: 'Items',
        Icon: RestaurantIcon,
        path: `${location.pathname}#items`,
      },
    ],
    [location],
  );

  const { currentTab, handleTabChange } = useTabNavigation(
    CREATE_PAGE_TABS,
    CREATE_PAGES_NAVIGATION_TABS.BASIC_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={CREATE_PAGE_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default CreatePagesTabs;
