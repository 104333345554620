import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import AppList from '../../../components/AppList';
import AppSwitch from '../../../components/AppSwitch';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectDishes } from '../../../redux/selectors/dishesSelectors';
import { PAGE_CREATION_DISHES_LIST_COLUMNS } from '../constants';
import { fetchDishData } from '../redux/dishesSlice';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { getFormattedPageCreationDishes } from '../utils/formatting.utils';

type Props = {
  onChange: (e: ChangeEvent<HTMLInputElement>, key: string) => void;
  values?: string[];
};

const PageDishesList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { dishesData, refreshing } = useAppSelector(selectDishes);

  const fetchDishDataCallback = useCallback(async () => {
    dispatch(setMenuPaginationSkip(undefined));
    dispatch(setMenuPaginationLimit(undefined));
    dispatch(setMenuPaginationSearchKey(''));
    await dispatch(fetchDishData());
  }, [dispatch]);

  useEffect(() => {
    fetchDishDataCallback();
  }, [fetchDishDataCallback]);

  const formattedDishDishesData = useMemo(() => {
    return dishesData.map(getFormattedPageCreationDishes);
  }, [dishesData]);

  return (
    <AppList
      columns={PAGE_CREATION_DISHES_LIST_COLUMNS}
      rows={formattedDishDishesData}
      hideHeader
      refreshing={refreshing}
      enableRowActions
      renderRowActions={(value) => {
        return (
          <AppSwitch
            id={value.key}
            name={'dishes'}
            value={value.key}
            checked={
              Array.isArray(props.values)
                ? props.values.includes(value.key)
                : false
            }
            onChange={(e) => props.onChange(e, value.key)}
          />
        );
      }}
    />
  );
};

export default PageDishesList;
