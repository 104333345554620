import { yupResolver } from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { array, object, string } from 'yup';

import AppButton from '../../../components/AppButton';
import AppSelector from '../../../components/AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTextInput from '../../../components/AppTextInput';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch } from '../../../hooks';
import PageCategoriesList from '../components/PageCategoriesList';
import PageDishesList from '../components/PageDishesList';
import { createPage } from '../redux/pagesSlice';
import { ENTITY_STATUS } from '../types/common.types';
import { CreatePageRequestBodyDTO } from '../types/pages.types';

type IPageForm = {
  name: string;
  description?: string;
  categories: string[];
  dishes: string[];
  status: ENTITY_STATUS;
};

const pageValidationSchema = object().shape(
  {
    name: string().required('Name is required'),
    description: string(),
    categories: array()
      .of(string().required())
      .when('dishes', {
        is: (dishes: string[]) => !dishes?.length,
        then: (schema) =>
          schema.min(1, 'Either categories or items are required'),
      })
      .required('Either categories or items are required'),
    dishes: array()
      .of(string().required())
      .when('categories', {
        is: (categories: string[]) => !categories?.length,
        then: (schema) =>
          schema.min(1, 'Either categories or items are required'),
      })
      .required('Either categories or items are required'),
    status: string()
      .oneOf(Object.values(ENTITY_STATUS))
      .required('Status is required'),
  },
  [['categories', 'dishes']],
);

const CreatePagesView = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showDishes, setShowDishes] = useState(false);
  const { openSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<IPageForm>({
    resolver: yupResolver(pageValidationSchema),
    defaultValues: {
      name: '',
      description: '',
      categories: [],
      dishes: [],
      status: ENTITY_STATUS.AVAILABLE,
    },
  });

  const onSubmit = async (data: IPageForm) => {
    try {
      const payload: CreatePageRequestBodyDTO = {
        name: data.name,
        description: data.description,
        categories: data.categories,
        dishes: data.dishes,
        status: data.status,
      };

      const response = await dispatch(createPage(payload));

      if (response.meta.requestStatus === 'fulfilled') {
        openSnackbar('Page created successfully!', SNACKBARTYPE.SUCCESS);
        navigate('/menu/pages');
      }
      reset();
    } catch {
      openSnackbar('Failed to create page', SNACKBARTYPE.ERROR);
    }
  };

  const handleOnCancel = () => {
    navigate('/menu/pages');
  };

  useEffect(() => {
    if (errors.categories?.message) {
      openSnackbar(errors.categories?.message, SNACKBARTYPE.ERROR);
    } else if (errors.dishes?.message) {
      openSnackbar(errors.dishes?.message, SNACKBARTYPE.ERROR);
    }
  }, [errors, openSnackbar]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <HashScroll hashInput="#basic-details">
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <AppTextInput
                  label="Name"
                  placeholder="E.g: Vegetarian"
                  type="text"
                  {...field}
                  onBlur={field.onBlur}
                  error={errors.name?.message}
                  data-testid="Page Name"
                />
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <AppSelector
                  label="Status"
                  placeholder="Available"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.status?.message}>
                  <MenuItem value={ENTITY_STATUS.AVAILABLE}>Available</MenuItem>
                  <MenuItem value={ENTITY_STATUS.HIDDEN}>Hidden</MenuItem>
                </AppSelector>
              )}
            />
          </Box>
        </HashScroll>

        <Divider sx={{ marginTop: '10px', marginBottom: '20px' }} />

        <HashScroll hashInput="#categories">
          <Accordion
            expanded={showCategories}
            onChange={(_, expanded) => setShowCategories(expanded)}
            elevation={0}
            sx={{
              marginLeft: -2,
              marginRight: -1,
              '&.Mui-expanded': {
                marginLeft: -2,
                marginRight: -1,
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="categories-bh-content"
              id="categories-bh-header">
              <Typography variant="h5" fontWeight={700}>
                Categories
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="categories"
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <PageCategoriesList
                    onChange={(e, key) => {
                      const newValues = Array.isArray(value) ? [...value] : [];

                      if (e.target.checked) {
                        onChange([...newValues, key]);
                      } else {
                        onChange(newValues.filter((v) => v !== key));
                      }
                    }}
                    values={value}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </HashScroll>

        <HashScroll hashInput="#items">
          <Accordion
            expanded={showDishes}
            onChange={(_, expanded) => setShowDishes(expanded)}
            elevation={0}
            sx={{
              marginLeft: -2,
              marginRight: -1,
              '&.Mui-expanded': {
                marginLeft: -2,
                marginRight: -1,
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="dishes-bh-content"
              id="dishes-bh-header">
              <Typography variant="h5" fontWeight={700}>
                Items
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="dishes"
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <PageDishesList
                    onChange={(e, key) => {
                      const newValues = Array.isArray(value) ? [...value] : [];

                      if (e.target.checked) {
                        onChange([...newValues, key]);
                      } else {
                        onChange(newValues.filter((v) => v !== key));
                      }
                    }}
                    values={value}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </HashScroll>

        <Stack direction="row-reverse" sx={{ mt: 2, mb: 4 }}>
          <AppButton
            size="large"
            title="Create"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            sx={{ ml: 2 }}
          />

          <AppButton
            size="large"
            title="Cancel"
            color="secondary"
            variant="contained"
            onClick={handleOnCancel}
          />
        </Stack>
      </form>
    </Box>
  );
};

export default CreatePagesView;
