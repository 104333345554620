import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { ReactNode } from 'react';

import { Colors } from '../../../theme/colors';
import AppEmptyTable from '../../AppEmptyTable';

interface EmptyListProps {
  emptyComponent?: ReactNode;
}

function EmptyList({ emptyComponent }: Readonly<EmptyListProps>) {
  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '8px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minHeight: '300px',
        background: Colors.backgroundLight,
      }}>
      {emptyComponent ?? (
        <AppEmptyTable icon={<SearchIcon sx={{ color: Colors.primary }} />} />
      )}
    </Box>
  );
}

export default EmptyList;
