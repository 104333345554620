import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import MaterialReactTable, {
  MRT_ColumnDef as MrtColumnDef,
} from 'material-react-table';
import { ReactNode, useCallback, useMemo } from 'react';

import { Colors } from '../../theme/colors';
import ChipCell from '../AppCells/ChipCell';
import CurrencyCell from '../AppCells/CurrencyCell';
import DateCell from '../AppCells/DateCell';
import TextCell from '../AppCells/TextCell';
import EmptyList from './components/EmptyList';
import {
  APP_LIST_COLUMN_TYPES,
  AppListCellWrapperProps,
  AppListProps,
  IAppListCell,
  IAppListRow,
} from './types/list.types';

const AppList = <T extends Record<string, unknown>>({
  columns = [],
  refreshing = false,
  rows = [],
  emptyComponent,
  hideHeader,
  enableRowActions,
  onClickActions,
  onClickRow,
  renderDetailPanel,
  renderRowActions,
}: Readonly<AppListProps<T>>) => {
  const renderCell = useCallback(
    (props: AppListCellWrapperProps<T>): ReactNode => {
      switch ((props.renderedCellValue as unknown as IAppListCell).type) {
        case APP_LIST_COLUMN_TYPES.CURRENCY:
          return <CurrencyCell {...props} />;

        case APP_LIST_COLUMN_TYPES.DATE:
          return <DateCell {...props} />;

        case APP_LIST_COLUMN_TYPES.CHIP:
          return <ChipCell {...props} />;

        default:
          return <TextCell {...props} />;
      }
    },
    [],
  );

  const columnsData: MrtColumnDef[] = useMemo(() => {
    return columns.map((column, index) => {
      const mappedColumn: MrtColumnDef = {
        accessorKey: column.accessorKey,
        header: column.header,
        size: 0,
        muiTableHeadCellProps: {
          align: column.headerAlign,
          sx: {
            py: 2,
            color: Colors.fontColorPrimary,
            fontWeight: 600,
            height: '42px',
            fontSize: '11px',
            ...(column.headerSx ?? {}),
            ...(index === columns.length - 1
              ? { width: 'unset', minWidth: 0 }
              : {}),
          },
        },
        muiTableBodyCellProps: {
          align: column.bodyAlign,
          sx: {
            py: 1.5,
            color: Colors.fontColorSecondary,
            fontWeight: 500,
            ...(column.bodySx ?? {}),
            ...(index === columns.length - 1
              ? { width: 'unset', minWidth: 0 }
              : {}),
          },
        },
        Cell: (props) =>
          renderCell(props as unknown as AppListCellWrapperProps<T>),
      };
      return mappedColumn;
    });
  }, [columns, renderCell]);

  return (
    <Box
      sx={{
        position: 'relative',
      }}>
      <Backdrop
        open={refreshing}
        sx={{
          zIndex: 999,
          position: 'absolute',
          color: 'transparent',
          backgroundColor: 'transparent',
        }}>
        <CircularProgress size="25px" thickness={5} />
      </Backdrop>

      {rows.length === 0 && !refreshing ? (
        <EmptyList emptyComponent={emptyComponent} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            background: Colors.backgroundPrimary,
            opacity: refreshing ? 0.5 : 1,
          }}>
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'auto',
              border: `1px solid ${Colors.borderPrimary}`,
              borderRadius: hideHeader ? '8px' : 0,
              borderBottomWidth: 0,
              borderRightWidth: hideHeader ? 1 : 0,
              borderLeftWidth: hideHeader ? 1 : 0,
              borderTopWidth: 1,
            }}>
            <MaterialReactTable
              enablePinning
              enableTableHead={!hideHeader}
              columns={columnsData}
              data={rows}
              enableTopToolbar={false}
              enableBottomToolbar={false}
              enableSorting={false}
              enableDensityToggle={false}
              enableRowActions={enableRowActions}
              enableExpanding={!!renderDetailPanel}
              enablePagination={false}
              positionActionsColumn="last"
              positionExpandColumn="last"
              muiTableHeadCellProps={{
                sx: {
                  py: 2,
                },
              }}
              localization={{
                actions: '',
              }}
              muiTableBodyRowProps={({ row }) => ({
                onClick: () => {
                  onClickRow?.(row.original as IAppListRow<T>);
                },
                sx: {
                  cursor: onClickRow ? 'pointer' : 'default',
                  ':active': {
                    opacity: onClickRow ? 0.5 : 1,
                  },
                },
              })}
              renderRowActions={({ row }) =>
                renderRowActions?.(row.original as T)
              }
              renderRowActionMenuItems={({ row, closeMenu }) => {
                const value = row.original as IAppListRow<T>;
                return value.actions.map((action) => (
                  <MenuItem
                    key={action.key}
                    onClick={() => {
                      onClickActions?.(action.key, value);
                      closeMenu();
                    }}
                    sx={{
                      color: action.fontColor ?? Colors.fontColorPrimary,
                      background:
                        action.backgroundColor ?? Colors.backgroundPrimary,
                      fontWeight: action.fontWeight ?? 500,
                    }}>
                    {action.name}
                  </MenuItem>
                ));
              }}
              renderDetailPanel={
                renderDetailPanel
                  ? ({ row }) =>
                      renderDetailPanel?.(row.original as IAppListRow<T>)
                  : undefined
              }
              enableColumnActions={false}
              initialState={{ density: 'compact' }}
              state={{
                isLoading: rows.length === 0 && refreshing,
              }}
              muiLinearProgressProps={{
                color: 'secondary',
              }}
              muiTableProps={{
                sx: {
                  boxShadow: 'none',
                  border: 'none',
                  width: '100%',
                },
              }}
              muiTableContainerProps={{
                sx: {
                  minWidth: '100%',
                  flex: '1',
                  maxWidth: '1024px',
                  '&::-webkit-scrollbar': {
                    height: '9px',
                    background: Colors.backgroundPrimary,
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: Colors.greyBorder,
                    borderRadius: '4px',
                  },
                },
              }}
              muiTableBodyProps={{
                sx: {
                  '& tr:nth-of-type(odd)': {
                    background: Colors.backgroundPrimary,
                  },
                  '& tr:nth-of-type(even)': {
                    background: Colors.backgroundPrimary,
                  },
                },
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AppList;
