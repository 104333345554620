import Collapse from '@mui/material/Collapse';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { memo, SyntheticEvent } from 'react';

import { Colors } from '../../theme/colors';
import { IVerticalTabItem } from '.';

export interface TabItemProps extends IVerticalTabItem {
  handleTabChange: (tab: Omit<IVerticalTabItem, 'tags' | 'icon'>) => void;
  value: number;
  currentTab: string;
  currentNestedTab?: string;
  horizontal?: boolean;
}

const TabItem = memo(
  ({
    value,
    Icon,
    label,
    currentTab,
    handleTabChange,
    id,
    path,
    currentNestedTab,
    nestedTabs,
    horizontal = false,
  }: TabItemProps) => {
    return (
      <Tab
        disableRipple
        id={value.toString()}
        label={
          <Box display={'flex'} flexDirection={'column'}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                fontSize: '14px',
                minWidth: horizontal ? '145px' : '200px',
                ...(horizontal && { justifyContent: 'center' }),
              }}>
              {Icon ? <Icon sx={{ fontSize: 'inherit' }} /> : null}
              <Typography
                variant="subtitle1"
                sx={{
                  marginLeft: horizontal ? '0px' : '15px',
                  textTransform: 'none',
                  fontWeight: 500,
                }}>
                {label}
              </Typography>
            </Box>
            <Collapse in={currentTab === id && (nestedTabs?.length ?? 0) > 0}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'baseline'}>
                {nestedTabs?.map((nestedTab) => (
                  <Box
                    key={nestedTab.id}
                    sx={{
                      fontSize: '14px',
                      paddingTop: '10px',
                      paddingLeft: '30px',
                      textTransform: 'none',
                      fontWeight: 500,
                      color:
                        currentNestedTab === nestedTab.id
                          ? Colors.primary
                          : Colors.fontColorSecondary,
                      opacity: currentNestedTab === nestedTab.id ? 1 : 0.7,
                    }}
                    onClick={(event: SyntheticEvent) => {
                      event.stopPropagation();
                      handleTabChange?.(nestedTab);
                    }}>
                    {nestedTab.label}
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
        }
        sx={{
          color: currentTab === id ? Colors.primary : Colors.fontColorSecondary,
          opacity: currentTab === id ? 1 : 0.7,
        }}
        onClick={() => {
          handleTabChange({
            id,
            label,
            path,
            Icon,
            nestedTabs,
          });
          if (nestedTabs && nestedTabs.length > 0)
            handleTabChange?.(nestedTabs[0]);
        }}
      />
    );
  },
);

export default TabItem;
