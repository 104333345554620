import { yupResolver } from '@hookform/resolvers/yup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { array, object, string } from 'yup';

import AppButton from '../../../components/AppButton';
import AppSelector from '../../../components/AppSelector';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTextInput from '../../../components/AppTextInput';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectPages } from '../../../redux/selectors/pagesSelectors';
import PageCategoriesList from '../components/PageCategoriesList';
import PageDishesList from '../components/PageDishesList';
import {
  getSinglePage,
  patchUpdatePage,
  resetPagesEditingDataState,
} from '../redux/pagesSlice';
import { ENTITY_STATUS } from '../types/common.types';
import { UpdatePageRequestBodyDTO } from '../types/pages.types';

type IPageForm = {
  name: string;
  description?: string;
  categories: string[];
  dishes: string[];
  status: ENTITY_STATUS;
};

const pageValidationSchema = object().shape(
  {
    name: string().required('Name is required'),
    description: string(),
    categories: array()
      .of(string().required())
      .when('dishes', {
        is: (dishes: string[]) => !dishes?.length,
        then: (schema) =>
          schema.min(1, 'Either categories or items are required'),
      })
      .required('Either categories or items are required'),
    dishes: array()
      .of(string().required())
      .when('categories', {
        is: (categories: string[]) => !categories?.length,
        then: (schema) =>
          schema.min(1, 'Either categories or items are required'),
      })
      .required('Either categories or items are required'),
    status: string()
      .oneOf(Object.values(ENTITY_STATUS))
      .required('Status is required'),
  },
  [['categories', 'dishes']],
);
const EditPagesView = () => {
  const [showCategories, setShowCategories] = useState(false);
  const [showDishes, setShowDishes] = useState(false);
  const { openSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { loadingSinglePage, editingData } = useAppSelector(selectPages);

  const getSinglePageCallback = useCallback(
    async (pathParamId: string) => {
      const res = await dispatch(getSinglePage(pathParamId));
      if (res.meta.requestStatus === 'rejected') {
        navigate('/menu/pages');
      }
    },
    [dispatch, navigate],
  );

  useLayoutEffect(() => {
    if (id) {
      getSinglePageCallback(id);
    }
  }, [id, getSinglePageCallback]);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<IPageForm>({
    resolver: yupResolver(pageValidationSchema),
    defaultValues: {
      name: '',
      description: '',
      categories: [],
      dishes: [],
      status: ENTITY_STATUS.AVAILABLE,
    },
  });

  const onSubmit = async (data: IPageForm) => {
    try {
      if (id) {
        const payload: UpdatePageRequestBodyDTO = {
          name: data.name,
          description: data.description,
          categories: data.categories,
          dishes: data.dishes,
          status: data.status,
        };

        const response = await dispatch(
          patchUpdatePage({
            id,
            body: payload,
          }),
        );

        if (response.meta.requestStatus === 'fulfilled') {
          openSnackbar(
            'The page has been updated successfully!',
            SNACKBARTYPE.SUCCESS,
          );
          navigate('/menu/pages');
        }
      }
    } catch {
      openSnackbar('Failed to edit the page', SNACKBARTYPE.ERROR);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getEditingData = () => {
      if (editingData) {
        setValue('name', editingData.name);
        setValue('description', editingData.description);
        setValue(
          'categories',
          editingData.categories.map((c) => c._id),
        );
        if (editingData.categories.length > 0) setShowCategories(true);

        setValue(
          'dishes',
          editingData.dishes.map((d) => d._id),
        );
        if (editingData.dishes.length > 0) setShowDishes(true);

        setValue(
          'status',
          editingData.status ? editingData.status : ENTITY_STATUS.AVAILABLE,
        );
      }
    };

    getEditingData();
  }, [editingData, setValue]);

  useEffect(() => {
    return () => {
      dispatch(resetPagesEditingDataState());
    };
  }, [dispatch]);

  const handleOnCancel = () => {
    navigate('/menu/pages');
  };

  useEffect(() => {
    if (errors.categories?.message) {
      openSnackbar(errors.categories?.message, SNACKBARTYPE.ERROR);
    } else if (errors.dishes?.message) {
      openSnackbar(errors.dishes?.message, SNACKBARTYPE.ERROR);
    }
  }, [errors, openSnackbar]);

  return (
    <Box
      sx={{
        position: 'relative',
        opacity: loadingSinglePage ? 0.5 : 1,
      }}>
      {loadingSinglePage && (
        <Backdrop
          open={true}
          sx={{
            position: 'absolute',
            zIndex: 999,
            backgroundColor: 'transparent',
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <HashScroll hashInput="#basic-details">
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <AppTextInput
                  label="Name"
                  placeholder="E.g: Vegetarian"
                  type="text"
                  {...field}
                  onBlur={field.onBlur}
                  error={errors.name?.message}
                  data-testid="Page Name"
                />
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <AppSelector
                  label="Status"
                  placeholder="Available"
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={errors.status?.message}>
                  <MenuItem value={ENTITY_STATUS.AVAILABLE}>Available</MenuItem>
                  <MenuItem value={ENTITY_STATUS.HIDDEN}>Hidden</MenuItem>
                </AppSelector>
              )}
            />
          </Box>
        </HashScroll>

        <HashScroll hashInput="#categories">
          <Accordion
            expanded={showCategories}
            onChange={(_, expanded) => setShowCategories(expanded)}
            elevation={0}
            sx={{
              marginLeft: -2,
              marginRight: -1,
              '&.Mui-expanded': {
                marginLeft: -2,
                marginRight: -1,
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="categories-bh-content"
              id="categories-bh-header">
              <Typography variant="h5" fontWeight={700}>
                Categories
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="categories"
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <PageCategoriesList
                    onChange={(e, key) => {
                      const newValues = Array.isArray(value) ? [...value] : [];

                      if (e.target.checked) {
                        onChange([...newValues, key]);
                      } else {
                        onChange(newValues.filter((v) => v !== key));
                      }
                    }}
                    values={value}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </HashScroll>

        <HashScroll hashInput="#items">
          <Accordion
            expanded={showDishes}
            onChange={(_, expanded) => setShowDishes(expanded)}
            elevation={0}
            sx={{
              marginLeft: -2,
              marginRight: -1,
              '&.Mui-expanded': {
                marginLeft: -2,
                marginRight: -1,
              },
            }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="dishes-bh-content"
              id="dishes-bh-header">
              <Typography variant="h5" fontWeight={700}>
                Items
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Controller
                name="dishes"
                control={control}
                render={({ field: { onChange, value = [] } }) => (
                  <PageDishesList
                    onChange={(e, key) => {
                      const newValues = Array.isArray(value) ? [...value] : [];

                      if (e.target.checked) {
                        onChange([...newValues, key]);
                      } else {
                        onChange(newValues.filter((v) => v !== key));
                      }
                    }}
                    values={value}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </HashScroll>

        <Stack direction="row-reverse" sx={{ mt: 2, mb: 4 }}>
          <AppButton
            size="large"
            title="Save"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
            loading={isSubmitting}
            sx={{ ml: 2 }}
          />

          <AppButton
            size="large"
            title="Cancel"
            color="secondary"
            variant="contained"
            onClick={handleOnCancel}
          />
        </Stack>
      </form>
    </Box>
  );
};

export default EditPagesView;
