import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

type HashScrollProps = {
  children: React.ReactNode;
  hashInput: string;
};

const HashScroll = ({ children, hashInput }: HashScrollProps) => {
  const { hash } = useLocation();
  const myRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (hash && hashInput === hash && myRef?.current) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
      myRef.current.focus();
    }
  }, [hash, hashInput]);

  return <section ref={myRef}>{children}</section>;
};

export default HashScroll;
