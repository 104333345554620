import RestaurantIcon from '@mui/icons-material/Restaurant';
import { useLocation } from 'react-router';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { CREATE_PAGES_NAVIGATION_TABS } from '../constants';

const EditPagesTabs = () => {
  const location = useLocation();

  const EDIT_PAGE_TABS: IVerticalTabItem[] = [
    {
      id: CREATE_PAGES_NAVIGATION_TABS.BASIC_DETAILS,
      label: 'Basic Details',
      Icon: RestaurantIcon,
      path: `${location.pathname}#basic-details`,
    },
    {
      id: CREATE_PAGES_NAVIGATION_TABS.CATEGORIES,
      label: 'Categories',
      Icon: RestaurantIcon,
      path: `${location.pathname}#categories`,
    },
    {
      id: CREATE_PAGES_NAVIGATION_TABS.DISHES,
      label: 'Items',
      Icon: RestaurantIcon,
      path: `${location.pathname}#items`,
    },
  ];

  const { currentTab, handleTabChange } = useTabNavigation(
    EDIT_PAGE_TABS,
    CREATE_PAGES_NAVIGATION_TABS.BASIC_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={EDIT_PAGE_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default EditPagesTabs;
