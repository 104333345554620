import TabPageLayout from '../../../hocs/AppPageLayout';
import CreatePagesTabs from '../components/CreatePagesTabs';
import CreatePagesView from '../views/CreatePagesView';

const breadCrumbs = [
  {
    title: 'Menu',
    link: '/menu',
  },
  {
    title: 'Pages',
    link: `/menu/pages`,
  },
  {
    title: 'Create Pages',
    link: `/menu/pages/create`,
  },
];

const CreatePagesPage = () => {
  return (
    <TabPageLayout
      title="Create Pages"
      TabComponent={CreatePagesTabs}
      ContentComponent={CreatePagesView}
      breadCrumbs={breadCrumbs}
      titleVariant="h5"
    />
  );
};

export default CreatePagesPage;
